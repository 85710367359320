import { ButtonGrid, PageWithBackground } from '../../components'
import { greensleeves } from '../../core/levels/greensleeves'
import { Confetti } from '../GameView/Confetti'
import { GameBottom } from '../GameView/GameBottom'
import { GameTop } from '../GameView/GameTop'
import { useConfettiRef } from '../GameView/useConfettiRef'
import { useNextScreenshot } from './useNextScreenshot'

const NO_OP = () => undefined

export function ThirdScreenshot() {
  const level = greensleeves

  useNextScreenshot(1)
  const confetti = useConfettiRef('win')

  return (
    <PageWithBackground color={level.color} background={level.background}>
      <Confetti ref={confetti} frozen />
      <GameTop
        confirmExit={false}
        points={level.song.length}
        maxPoints={level.song.length}
        isHighscore={true}
        flashHighscore={false}
        mode={'win'}
      />
      <ButtonGrid
        colorScheme={level.colorScheme}
        size={level.size}
        active={5}
        disabled={true}
        onClick={NO_OP}
      />
      <GameBottom level={level} mode={'win'} onRestart={NO_OP} />
    </PageWithBackground>
  )
}
