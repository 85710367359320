import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const bellaCiao: Level = {
  id: '3e48273d-5a4b-403a-b7e8-8260b91c5eb0',
  title: (t) => t('songs.bellaCiao'),
  artist: (t) => t('artists.traditional'),
  colorScheme: makeScheme('#fc647f', '#89bee8', '#fae596', '#ffa38c'),
  color: 'rgb(182, 190, 125)',
  background,
  size: 25,
  song: song,
}
