import { ad } from './ad'
import { arc } from './arc'
import { arrow } from './arrow'
import { bell } from './bell'
import { circle } from './circle'
import { clubs } from './clubs'
import { cross } from './cross'
import { crown } from './crown'
import { cup } from './cup'
import { diamond } from './diamond'
import { eye } from './eye'
import { fish } from './fish'
import { heart } from './heart'
import { horn } from './horn'
import { left } from './left'
import { lightning } from './lightning'
import { look } from './look'
import { moon } from './moon'
import { note } from './note'
import { pentagon } from './pentagon'
import { plus } from './plus'
import { retry } from './retry'
import { right } from './right'
import { shield } from './shield'
import { spades } from './spades'
import { square } from './square'
import { star } from './star'
import { sun } from './sun'
import { tap } from './tap'
import { tower } from './tower'
import { triangle } from './triangle'
import { wave } from './wave'
import { wing } from './wing'

export type IconShape = keyof typeof SHAPES

export const SHAPES = {
  ad,
  arc,
  arrow,
  bell,
  circle,
  clubs,
  cross,
  crown,
  cup,
  diamond,
  eye,
  fish,
  heart,
  horn,
  left,
  lightning,
  look,
  moon,
  note,
  pentagon,
  plus,
  retry,
  right,
  shield,
  spades,
  square,
  sun,
  tap,
  star,
  tower,
  triangle,
  wave,
  wing,
}
