import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const blueDanube: Level = {
  id: '127e5531-c6e9-45f4-9428-7240791acfb5',
  title: (t) => t('songs.blueDanube'),
  artist: (t) => t('artists.strauss'),
  colorScheme: makeScheme('#60dd8e', '#3f9f7f', '#188a8d', '#17577e'),
  color: 'rgb(62, 114, 196)',
  background,
  size: 25,
  song: song,
}
