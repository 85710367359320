import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'
import { Colors } from '../constants'
import { Icon } from './icons'

interface Props {
  points: number
  maxPoints: number
  isHighscore?: boolean
  flashHighscore?: boolean
  className?: string
}

export function Points(props: Props) {
  const { t } = useTranslation()
  const show = props.isHighscore || props.flashHighscore
  return (
    <PointDisplay className={props.className}>
      <PointsValue>
        {props.points} / {props.maxPoints}{' '}
      </PointsValue>
      <Icon inline shape="note" />
      {show && (
        <Highscore className={props.flashHighscore ? 'flash' : ''}>
          {t('level.highscore')}
        </Highscore>
      )}
    </PointDisplay>
  )
}

const PointsValue = styled.span`
  font-weight: bold;
`

const PointDisplay = styled.div`
  position: relative;
`

const flash = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Highscore = styled.div`
  position: absolute;
  right: 4px;
  bottom: -24px;
  text-align: right;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  color: ${Colors.Yellow};
  white-space: pre;
  text-shadow: 1px 2px black;

  &.flash {
    animation: ${flash} alternate infinite 0.2s;
  }
`
