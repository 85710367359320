import Hammer from '@egjs/hammerjs'
import { Children, ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { usePanel } from './usePanel'

interface Props {
  height: number | string
  children: ReactNode
}

export function Carousel({ children, height }: Props) {
  const ref = useRef<HTMLDivElement>(null)
  const count = Children.count(children)
  const [panel, dispatch] = usePanel(count)

  useEffect(() => {
    if (ref.current) {
      const mc = new Hammer.Manager(ref.current, {
        inputClass: Hammer.TouchInput,
      })
      mc.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }))
      mc.on('swipeleft', () => dispatch('next'))
      mc.on('swiperight', () => dispatch('previous'))
      return () => mc.destroy()
    }
  }, [])

  return (
    <Container ref={ref} style={{ height }}>
      <Viewport
        style={{ width: 100 * count + 'vw', left: -100 * panel + 'vw' }}
      >
        {Children.map(children, (child, index) => (
          <Panel style={{ left: 100 * index + 'vw' }}>{child}</Panel>
        ))}
      </Viewport>
      <Dots>
        {Children.map(children, (child, index) => (
          <Dot active={index === panel} />
        ))}
      </Dots>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
`

const Viewport = styled.div`
  position: relative;
  height: 100%;
  left: 0;
  transition: left 0.2s;
`

const Panel = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
`

const Dots = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Dot = styled.div<{ active: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin: 0 4px;
  background-color: ${({ active }) =>
    active ? 'white' : 'rgba(255, 255, 255, 0.3)'};
`
