import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const jingleBells: Level = {
  id: '600015ee-0295-4b25-981f-c4d60c75b101',
  title: (t) => t('songs.jingleBells'),
  artist: (t) => t('artists.pierpont'),
  colorScheme: makeScheme('#4390bc', '#68a7ca', '#8dbdd8', '#d8e9f3'),
  color: 'rgb(89, 152, 146)',
  background,
  size: 9,
  song: song,
}
