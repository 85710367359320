import { Plugins } from '@capacitor/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  ButtonGrid,
  PageBottom,
  PageTop,
  PageWithBackground,
} from '../../components'
import { TEXT_SHADOW } from '../../constants'
import { allLevels, makeScheme } from '../../core'
import { BackPriority, useBackButton } from '../../hooks'

const colors = makeScheme('#f3cb3d', '#91d097', '#6aace9', '#d96363')

const randomLevel = () =>
  allLevels[Math.floor(Math.random() * allLevels.length)]

export function MainMenu() {
  const { t } = useTranslation()
  const [song] = useState(() => randomLevel().song)
  const [index, setIndex] = useState(0)

  useBackButton(BackPriority.App, () => Plugins.App.exitApp())

  function onClick() {
    song.playChord(index % song.length)
    setIndex((i) => i + 1)
  }

  return (
    <PageWithBackground>
      <Top>
        <ButtonGrid
          size={4}
          colorScheme={colors}
          animate
          width={100}
          onClick={onClick}
        />
        <Title>{t('title')}</Title>
      </Top>
      <Bottom>
        <MenuItem to="/levels">{t('menu.play')}</MenuItem>
        <MenuItem to="/languages">{t('menu.changeLanguage')}</MenuItem>
      </Bottom>
    </PageWithBackground>
  )
}

const Top = styled(PageTop)`
  flex: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 24px;
`

const Title = styled.h1`
  margin: 0 0 0 16px;
  font-size: 39px;
  font-family: 'Arsenal';
  filter: ${TEXT_SHADOW};
  white-space: pre-line;
`

const Bottom = styled(PageBottom)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MenuItem = styled(Link)`
  display: block;
  padding: 0;
  margin-bottom: 12px;
  font-size: 24px;
  color: inherit;
  text-decoration: none;
  filter: ${TEXT_SHADOW};
`
