import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 130 },
  `
    4C4 4C4 4G4 4G4
    4A4 4A4 2G4
    4F4 4F4 4E4 4E4
    4D4 4D4 2C4
  `
)
