import { useEffect, useState } from 'react'

export function useEntered(size: number, animate: boolean) {
  const [entered, setEntered] = useState(animate ? 0 : size)
  useEffect(() => {
    if (entered < size) {
      const timeout = setTimeout(() => {
        setEntered((x) => x + 1)
      }, 10)
      return () => clearTimeout(timeout)
    }
  }, [entered, size])
  return entered
}
