import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const nachtmusik: Level = {
  id: '4edaa327-e645-4d47-9fa6-676e00190eb2',
  title: (t) => t('songs.nachtmusik'),
  artist: (t) => t('artists.mozart'),
  colorScheme: makeScheme('#c3727c', '#6e67be', '#ae529f', '#632995'),
  color: 'rgb(108, 101, 111)',
  background,
  size: 9,
  song: song,
}
