import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  PageBottom,
  PageHeader,
  PageTop,
  PageWithBackground,
} from '../../components'
import { Colors } from '../../constants'
import { levels } from '../../core'
import { Carousel } from './Carousel'
import { LevelList } from './LevelList'

export function LevelSelect() {
  const { t } = useTranslation()

  return (
    <PageWithBackground>
      <PageTop>
        <PageHeader title={t('menu.levelSelect')} />
      </PageTop>
      <Carousel height={374 + 64}>
        <Panel>
          <PanelTitle>{t('menu.beginner')}</PanelTitle>
          <LevelList levels={levels.beginner} />
        </Panel>
        <Panel>
          <PanelTitle>{t('menu.intermediate')}</PanelTitle>
          <LevelList levels={levels.intermediate} />
        </Panel>
        <Panel>
          <PanelTitle>{t('menu.expert')}</PanelTitle>
          <LevelList levels={levels.expert} />
        </Panel>
        <Panel>
          <PanelTitle>{t('menu.master')}</PanelTitle>
          <LevelList levels={levels.master} />
        </Panel>
      </Carousel>
      <PageBottom />
    </PageWithBackground>
  )
}

const Panel = styled.div`
  margin: 32px auto;
  width: calc(100vw - 32px);
  max-width: 600px;
  border-radius: 16px;
  padding: 8px;
  background-color: ${Colors.Background};
  box-shadow: 0px 8px 20px 0 rgba(0, 0, 0, 0.7);
`

const PanelTitle = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 1;
  margin-top: 8px;
  margin-bottom: 16px;
`
