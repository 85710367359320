import { useParams } from 'react-router'
import { levelsById } from '../../core'
import { GamePage } from './GamePage'

export function GameView() {
  const { levelId } = useParams<{ levelId: string }>()
  const level = levelsById[levelId]

  return <GamePage key={level.id} level={level} />
}
