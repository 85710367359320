import { useEffect } from 'react'
import { useParams } from 'react-router'
import { i18n } from '../../i18n'
import { FirstScreenshot } from './FirstScreenshot'
import { SecondScreenshot } from './SecondScreenshot'
import { ThirdScreenshot } from './ThirdScreenshot'

export function Screenshot() {
  const { id, lang } = useParams<{ id: string; lang: string }>()
  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [lang])

  const View = screenshots[parseInt(id) - 1]
  return <View />
}

const screenshots = [FirstScreenshot, SecondScreenshot, ThirdScreenshot]
