import { ReactNode } from 'react'
import { SvgIcon } from './SvgIcon'

interface Props {
  size?: number | string
  inline?: boolean
  className?: string
}

export function makeSvgIcon(children: ReactNode) {
  return function ({ size, inline, className }: Props) {
    return (
      <SvgIcon inline={inline} size={size} className={className}>
        {children}
      </SvgIcon>
    )
  }
}
