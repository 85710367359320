import { IconShape, SHAPES } from './shapes'

interface Props {
  shape: IconShape
  inline?: boolean
  size?: number | string
  className?: string
}

export function Icon({ shape, inline, size, className }: Props) {
  const Component = SHAPES[shape]
  return <Component inline={inline} size={size} className={className} />
}
