import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const furElise: Level = {
  id: '4f79d4fb-b8fa-44be-8f8e-5332226452d2',
  title: (t) => t('songs.furElise'),
  artist: (t) => t('artists.beethoven'),
  colorScheme: makeScheme('#007676', '#004443', '#005655', '#006766'),
  color: 'rgb(4, 104, 81)',
  background,
  size: 25,
  song: song,
}
