import { Suspense } from 'react'
import { MemoryRouter, BrowserRouter } from 'react-router-dom'
import { config } from './config'
import { Routes } from './pages/Routes'
import {
  AdProvider,
  InitProvider,
  ScoreProvider,
  GlobalStyle,
} from './providers'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Router: any = config.useRealRouter ? BrowserRouter : MemoryRouter

export function App() {
  return (
    <Suspense fallback="loading">
      <InitProvider>
        <AdProvider>
          <ScoreProvider>
            <Router>
              <GlobalStyle />
              <Routes />
            </Router>
          </ScoreProvider>
        </AdProvider>
      </InitProvider>
    </Suspense>
  )
}
