import cx from 'classnames'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Icon, IconShape } from '..'
import { config } from '../../config'

interface Props {
  colorTop: string
  colorSide: string
  colorLight: string
  size: number
  shape: IconShape
  active?: boolean
  entering?: boolean
  disabled?: boolean
  onClick?: () => void
}

export function IconButton(props: Props) {
  const [pressed, setPressed] = useState(false)
  const [disableClicks, setDisableClicks] = useState(false)

  useEffect(() => {
    if (pressed) {
      const timeout = setTimeout(() => setPressed(false), 150)
      return () => clearTimeout(timeout)
    }
  }, [pressed])

  function onTap() {
    if (!disableClicks) {
      setDisableClicks(true)
    }
    registerClick()
  }

  function onClick() {
    if (!disableClicks) {
      registerClick()
    }
  }

  function registerClick() {
    if (!props.disabled) {
      setPressed(true)
      props.onClick?.()
    }
  }

  const active = props.active || pressed

  return (
    <StyledButton
      color={props.colorSide}
      size={props.size}
      onTouchStart={onTap}
      onMouseDown={onClick}
      className={cx(active && 'active', props.entering && 'entering')}
    >
      <Content
        color={active ? props.colorLight : props.colorTop}
        size={props.size}
      >
        <Icon shape={props.shape} size={Math.floor(props.size / 2)} />
      </Content>
    </StyledButton>
  )
}

const StyledButton = styled.button<{ color: string; size: number }>`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ color }) => color};
  color: ${({ color }) => color};
  border: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  border-radius: ${({ size }) => Math.floor(size / 6)}px;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  margin-top: 0;
  box-shadow: 0px ${({ size }) => size / 20}px ${({ size }) => size / 17}px 0
    rgba(0, 0, 0, 0.7);
  top: 0;
  opacity: 1;
  transition: ${config.screenshotMode ? 'unset' : '0.1s'};

  &.active {
    margin-top: ${({ size }) => Math.floor(size * 0.1)}px;
    height: ${({ size }) => size - Math.floor(size * 0.1)}px;
  }

  &.entering {
    top: -10px;
    opacity: 0;
  }
`

const Content = styled.div<{ color: string; size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => Math.floor(size * 0.85)}px;
  background-color: ${({ color }) => color};
  border-radius: ${({ size }) => Math.floor(size / 6)}px;
  display: flex;
  justify-content: center;
  align-items: center;
`
