import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const odeToJoy: Level = {
  id: '2075d465-ff0a-4b1c-9a9a-2fcebd8f0795',
  title: (t) => t('songs.odeToJoy'),
  artist: (t) => t('artists.beethoven'),
  colorScheme: makeScheme('#560606', '#da2423', '#900b0b', '#730808'),
  color: 'rgb(160, 47, 47)',
  background,
  size: 25,
  song: song,
}
