import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 130 },
  `
    4A5 8A5 8A5 8G5 8F5
    4E5 8E5 8E5 8D5 8C5
    4D5 8D5 8D5 8E5 8D5
    4C5 8B4 8C5 4A4
  `
)
