import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const swanLake: Level = {
  id: '104130e4-89a4-4add-b5e5-801c600973bc',
  title: (t) => t('songs.swanLake'),
  artist: (t) => t('artists.tchaikovsky'),
  colorScheme: makeScheme('#e7809e', '#f3b5ab', '#ee9ca3', '#fad7b7'),
  color: 'rgb(110, 109, 132)',
  background,
  size: 9,
  song: song,
}
