import { MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'
import { Colors } from '../constants'
import { BackPriority, useBackButton } from '../hooks'

interface Props {
  open: boolean
  onClose: () => void
  children?: ReactNode
  className?: string
}

export function Modal(props: Props) {
  function onBackdropClick(e: MouseEvent) {
    if (e.target === e.currentTarget) {
      props.onClose()
    }
  }

  useBackButton(props.open && BackPriority.Modal, props.onClose)

  return (
    <Backdrop onClick={onBackdropClick} className={props.open ? 'open' : ''}>
      <Body className={props.className}>{props.children}</Body>
    </Backdrop>
  )
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  padding: 15px;
  left: -200vw;
  transition: background-color 0.2s, left 0.2s step-end;

  &.open {
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: background-color 0.2s, left 0.2s step-start;
  }
`

const Body = styled.div`
  width: 100%;
  max-width: 600px;
  min-height: 100px;
  max-height: 90vh;
  overflow: auto;
  border-radius: 8px;
  background-color: ${Colors.Background};
  box-shadow: 0px 8px 20px 0 rgba(0, 0, 0, 0.7);
  transform: scale(0);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;

  .open & {
    transform: scale(1);
    opacity: 1;
  }
`
