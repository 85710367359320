import { forwardRef, Ref, useEffect, useImperativeHandle, useRef } from 'react'
import styled from 'styled-components'

export interface ConfettiElement {
  emitParticles: () => void
}

interface Props {
  frozen?: boolean
}

const NOOP = () => {
  /* empty */
}

export const Confetti = forwardRef<ConfettiElement, Props>(
  (props, ref: Ref<ConfettiElement>) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const emitParticles = useRef(NOOP)

    useImperativeHandle(ref, () => ({
      emitParticles: () => emitParticles.current(),
    }))

    useEffect(() => {
      if (canvasRef.current) {
        const { createParticles, stop } = start(
          canvasRef.current,
          !!props.frozen
        )
        emitParticles.current = createParticles
        return stop
      }
    }, [props.frozen])

    return <ConfettiDisplay ref={canvasRef} />
  }
)

const ConfettiDisplay = styled.canvas`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  pointer-events: none;
`

class ConfettiParticle {
  color = 0
  opacity = 50
  diameter = 0
  tilt = 0
  tiltAngleIncrement = 0
  tiltAngle = 0
  particleSpeed = 0.2
  waveAngle = 0
  x = 0
  y = 0

  constructor(
    public context: CanvasRenderingContext2D,
    public width: number,
    public height: number
  ) {
    this.reset()
  }

  reset() {
    this.opacity = 1
    this.color = Math.floor(Math.random() * 360)
    this.x = Math.random() * this.width
    this.y = Math.random() * this.height - this.height
    this.diameter = Math.random() * 10 + 10
    this.tilt = 0
    this.tiltAngleIncrement = Math.random() * 0.1 + 0.04
    this.tiltAngle = 0
  }

  darken() {
    if (this.y < 100 || this.opacity <= 0) return
    this.opacity -= 5 / this.height
    if (this.opacity <= 0) {
      this.opacity = 0
    }
  }

  update() {
    if (!this.complete()) {
      this.waveAngle += this.tiltAngleIncrement
      this.tiltAngle += this.tiltAngleIncrement
      this.tilt = Math.sin(this.tiltAngle) * 12
      this.x += Math.sin(this.waveAngle)
      this.y += (Math.cos(this.waveAngle) + this.diameter) * this.particleSpeed
      this.darken()
    }
  }

  complete() {
    return this.y > this.height + 20
  }

  draw() {
    const x = this.x + this.tilt
    this.context.beginPath()
    this.context.lineWidth = this.diameter
    this.context.strokeStyle =
      'hsla(' + this.color + ', 50%, 50%, ' + this.opacity + ')'
    this.context.moveTo(x + this.diameter / 2, this.y)
    this.context.lineTo(x, this.y + this.tilt + this.diameter / 2)
    this.context.stroke()
  }
}

function start(canvas: HTMLCanvasElement, frozen: boolean) {
  let width = window.innerWidth
  let height = window.innerHeight
  let particles: ConfettiParticle[] = []

  // particle canvas
  const context = canvas.getContext('2d')
  if (!context) {
    return { createParticles: NOOP, stop: NOOP }
  }
  canvas.width = width
  canvas.height = height

  // update canvas size
  const updateSize = () => {
    width = window.innerWidth
    height = window.innerHeight
    canvas.width = width
    canvas.height = height
  }

  // create confetti particles
  const createParticles = () => {
    particles = []
    for (let i = 0; i < 100; ++i) {
      particles.push(new ConfettiParticle(context, width, height))
    }
  }

  if (frozen) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(Math as any).seedrandom('cats')
    updateSize()
    createParticles()
    context.clearRect(0, 0, width, height)
    for (let i = 0; i < 3 * 60; i++) {
      for (const p of particles) {
        p.update()
      }
    }
    for (const p of particles) {
      p.draw()
    }

    return {
      createParticles() {
        // empty
      },
      stop() {
        // empty
      },
    }
  }

  let running = true
  const runAnimation = () => {
    if (!running) {
      return
    }
    requestAnimationFrame(runAnimation)
    context.clearRect(0, 0, width, height)

    let completeCount = 0
    for (const p of particles) {
      p.width = width
      p.height = height
      p.update()
      p.draw()
      if (p.complete()) {
        completeCount++
      }
    }
    if (completeCount === particles.length && particles.length > 0) {
      particles = []
    }
  }

  updateSize()
  window.addEventListener('resize', updateSize)

  runAnimation()

  return {
    createParticles,
    stop() {
      window.removeEventListener('resize', updateSize)
      running = false
    },
  }
}
