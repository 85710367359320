import { makeSvgIcon } from '../makeSvgIcon'

export const look = makeSvgIcon(
  <>
    <path
      fillRule="evenodd"
      d="M24 12C24 12 21 4 12 4C3 4 0 12 0 12C0 12 3 20 12 20C21 20 24 12 24 12ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
    />
    <circle cx="12" cy="12" r="3" />
  </>
)
