import { config } from '../../config'
import { initHowler } from '../../core'
import { initI18n } from '../../i18n'
import { hideSplashScreen } from './hideSplashScreen'
import { initNativeSettings } from './initNativeSettings'
import { preventZoom } from './preventZoom'
import { unmuteIosAudio } from './unmuteIosAudio'

export async function init() {
  if (config.screenshotMode) {
    initI18n()
    hideSplashScreen()
    return
  }
  preventZoom()
  initI18n()
  unmuteIosAudio()
  await Promise.all([initHowler(), initNativeSettings()])
  hideSplashScreen()
}
