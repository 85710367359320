import { useEffect, useReducer } from 'react'

function readPanel() {
  const stored = localStorage.getItem('panel')
  if (stored) {
    const parsed = JSON.parse(stored)
    if (typeof parsed === 'number') {
      return parsed
    }
  }
  return 0
}

function writePanel(value: number) {
  localStorage.setItem('panel', JSON.stringify(value))
}

function panelReducer(count: number) {
  return function reducer(state: number, action: 'previous' | 'next') {
    if (action === 'next') {
      return Math.min(state + 1, count - 1)
    } else if (action === 'previous') {
      return Math.max(state - 1, 0)
    }
    return state
  }
}

export function usePanel(count: number) {
  const [panel, dispatch] = useReducer(panelReducer(count), 0, readPanel)
  useEffect(() => {
    writePanel(panel)
  }, [panel])
  return [panel, dispatch] as const
}
