import { Note } from './Song'

export function chordsToPattern(chords: Note[][]): number[] {
  const knownTop = new Map<string, number>()
  const knownFull = new Map<string, number>()
  const result: number[] = []
  let i = 0
  for (const chord of chords) {
    const top = chordToTop(chord)
    const full = chordToFull(chord)
    const topI = knownTop.get(top)
    const fullI = knownFull.get(full)
    if (topI !== undefined) {
      result.push(topI)
      knownFull.set(full, topI)
    } else if (fullI !== undefined) {
      result.push(fullI)
      if (top !== '') {
        knownTop.set(top, fullI)
      }
    } else {
      i++
      result.push(i)
      knownFull.set(full, i)
      if (top !== '') {
        knownTop.set(top, i)
      }
    }
  }
  return result
}

function chordToTop(chord: Note[]): string {
  return chord
    .filter((x) => x.octave >= 4)
    .sort(compareNotes)
    .map((x) => x.key)
    .join()
}

function chordToFull(chord: Note[]): string {
  return chord
    .map((x) => x)
    .sort(compareNotes)
    .map((x) => x.key)
    .join()
}

const notes = ['C', 'D', 'E', 'F', 'G', 'A', 'B']
function compareNotes(a: Note, b: Note) {
  if (a.octave !== b.octave) {
    return a.octave - b.octave
  } else {
    return notes.indexOf(a.name) - notes.indexOf(b.name)
  }
}
