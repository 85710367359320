import { Redirect, Route, Switch } from 'react-router-dom'
import { config } from '../config'
import { useInitialized } from '../hooks'
import { DebugPage } from './debug/DebugPage'
import { GameView } from './GameView/GameView'
import { LanguageSelect } from './LanguageSelect/LanguageSelect'
import { LevelSelect } from './LevelSelect/LevelSelect'
import { MainMenu } from './MainMenu/MainMenu'
import { Screenshot } from './Screenshot/Screenshot'

export function Routes() {
  const initialized = useInitialized()
  if (!initialized && config.screenshotMode) {
    return null
  }

  return (
    <Switch>
      <Route exact path="/menu" component={MainMenu} />
      <Route exact path="/languages" component={LanguageSelect} />
      <Route exact path="/levels" component={LevelSelect} />
      <Route exact path="/levels/:levelId" component={GameView} />

      <Route exact path="/screenshot/:id/:lang" component={Screenshot} />
      <Route exact path="/debug" component={DebugPage} />

      {config.debug && <Redirect exact from="/" to="/debug" />}
      <Redirect exact from="/" to="/menu" />
    </Switch>
  )
}
