import i18next from 'i18next'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

const languages = ['en', 'es', 'de', 'pl', 'ru']
function setNextLanguage() {
  const i = languages.indexOf(i18next.language)
  i18next.changeLanguage(languages[(i + 1) % languages.length])
}

export function useNextScreenshot(id: number) {
  const history = useHistory()
  useEffect(() => {
    function onClick() {
      if (id === 1) {
        setNextLanguage()
      }
      history.push(`/screenshots/${id}`)
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [])
}
