import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 120 },
  `
    4A4
    4A2+2C5 4C3 4E3+4D5
    4A2+4.E5 4C3 8F5 4E3+4E5
    4G2+2D5 4B2 4D3+4B4
    4G2+4.G4 4B2 8A4 4D3+4B4
    4F2+2C5 4A2 4C3+4A4
    4F2+4.A4 4A2 8Ab4 4C3+4A4
    4E2+2B4 4Ab2 4B2+4Ab4
    4E2+2E4
  `
)
