import { IconShape } from '..'
import { colorsAt, ColorScheme } from '../../core'

export interface ButtonDescription {
  shape: IconShape
  colorTop: string
  colorSide: string
  colorLight: string
}

export function getButtons(
  scheme: ColorScheme,
  size: 4 | 9 | 16 | 25
): ButtonDescription[] {
  const shapes: (IconShape | false)[] = [
    // row 1
    'heart',
    size > 16 && 'arc',
    size > 4 && 'triangle',
    size > 9 && 'shield',
    'clubs',
    // row 2
    size > 16 && 'cup',
    size > 16 && 'wave',
    size > 16 && 'eye',
    size > 16 && 'lightning',
    size > 16 && 'horn',
    // row 3
    size > 4 && 'square',
    size > 16 && 'moon',
    size > 4 && 'sun',
    size > 9 && 'plus',
    size > 4 && 'circle',
    // row 4
    size > 9 && 'wing',
    size > 16 && 'bell',
    size > 9 && 'arrow',
    size > 9 && 'pentagon',
    size > 9 && 'tower',
    // row 5
    'spades',
    size > 16 && 'crown',
    size > 4 && 'cross',
    size > 9 && 'fish',
    'diamond',
  ]
  const side = Math.sqrt(size)
  return shapes
    .filter((x): x is IconShape => typeof x === 'string')
    .map((shape, i) => {
      const x = i % side
      const y = Math.floor(i / side)
      const colors = colorsAt(scheme, x / (side - 1), y / (side - 1))
      return { shape, ...colors }
    })
}
