import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 60 },
  `
    16D5+16D6 16E5+16E6 16C5+16C6 8A4+8A5 16B4+16B5 8G4+8G5
    16D5+16D4 16E5+16E4 16C5+16C4 8A4+8A3 16B4+16B3 8G4+8G3
    16D4+16D3 16E4+16E3 16C4+16C3 8A3+8A2 16B3+16B2 16A3+16A2 16Ab3+16Ab2
    8G3+8G2 8p 8G4+8B4+D5+G5+8G2+8G1
  `
)
