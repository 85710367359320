import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const symphony5: Level = {
  id: '9654c3da-0c0b-4c7c-a495-a4c534f20eee',
  title: (t) => t('songs.symphony5'),
  artist: (t) => t('artists.beethoven'),
  colorScheme: makeScheme('#7162d1', '#dfc3db', '#9d9adf', '#9548b9'),
  color: 'rgb(126, 81, 151)',
  background,
  size: 4,
  song: song,
}
