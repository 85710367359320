import { ComponentPropsWithoutRef } from 'react'
import styled from 'styled-components'
import { Colors } from '../constants'
import defaultBackground from './background.png'

interface Props extends ComponentPropsWithoutRef<'div'> {
  background?: string
  color?: string
}

export function PageWithBackground({ background, color, ...props }: Props) {
  return (
    <Page
      style={{
        backgroundImage: `url(${background ?? defaultBackground})`,
        backgroundColor: color ?? Colors.Background,
      }}
      {...props}
    />
  )
}

export const Page = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Colors.Background};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: var(--inset-top, env(safe-area-inset-top));
  padding-bottom: calc(
    var(--inset-bottom, env(safe-area-inset-bottom)) + var(--banner-height, 0px)
  );
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
`

export const PageTop = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
`

export const PageBottom = styled.div`
  width: 100%;
  flex: 1;
`
