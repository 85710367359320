import { makeSvgIcon } from '../makeSvgIcon'

export const cross = makeSvgIcon(
  <>
    <rect
      x="6.34315"
      y="2.1005"
      width="22"
      height="6"
      transform="rotate(45 6.34315 2.1005)"
    />
    <rect
      x="21.8995"
      y="6.34315"
      width="22"
      height="6"
      transform="rotate(135 21.8995 6.34315)"
    />
  </>
)
