import { useEffect, useState } from 'react'
import styled from 'styled-components'

export function DebugPage() {
  const dimensions = useDimensions()
  const insets = useInsets()

  return (
    <WhiteDiv>
      <div>Width = {dimensions.width}px</div>
      <div>Height = {dimensions.height}px</div>
      <div>DPR = {dimensions.dpr}</div>
      <div>Insets.top = {insets.top}px</div>
      <div>Insets.bottom = {insets.bottom}px</div>
      <TopInset style={{ height: insets.top }} />
      <BottomInset style={{ height: insets.bottom }} />
    </WhiteDiv>
  )
}

function useDimensions() {
  const [dimensions, setDimensions] = useState(getDimensions)
  useEffect(() => {
    function update() {
      setDimensions(getDimensions)
    }
    const interval = setInterval(update, 1000)
    return () => clearInterval(interval)
  }, [])
  return dimensions
}

function getDimensions() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    dpr: window.devicePixelRatio,
  }
}

function useInsets() {
  const [top, setTop] = useState(0)
  const [bottom, setBottom] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const topDiv = document.createElement('div')
      topDiv.style.paddingTop =
        'var(--android-insets-top, env(safe-area-inset-top))'
      topDiv.style.position = 'absolute'
      document.body.appendChild(topDiv)
      setTop(topDiv.getBoundingClientRect().height)

      const bottomDiv = document.createElement('div')
      bottomDiv.style.paddingBottom =
        'var(--android-insets-bottom, env(safe-area-inset-bottom))'
      bottomDiv.style.position = 'absolute'
      document.body.appendChild(bottomDiv)
      setBottom(bottomDiv.getBoundingClientRect().height)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return { top, bottom }
}

const WhiteDiv = styled.div`
  background-color: white;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: black;
  font-size: 16px;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TopInset = styled.div`
  background-color: red;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
`

const BottomInset = styled.div`
  background-color: blue;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
`
