import { ButtonGrid, PageWithBackground } from '../../components'
import { Level } from '../../core'
import { AdModal } from './AdModal'
import { Confetti } from './Confetti'
import { GameBottom } from './GameBottom'
import { GameTop } from './GameTop'
import { useConfettiRef } from './useConfettiRef'
import { useGame } from './useGame'
import { useHighscore } from './useHighscore'

interface Props {
  level: Level
}

export function GamePage({ level }: Props) {
  const game = useGame(level.size, level.song)
  const { highscore, flashHighscore } = useHighscore(game, level.id)
  const confetti = useConfettiRef(game.mode)

  const confirmExit =
    (game.mode === 'observe' || game.mode === 'repeat') && game.points !== 0
  const isHighscore = highscore !== 0 && game.points > highscore

  return (
    <PageWithBackground color={level.color} background={level.background}>
      <Confetti ref={confetti} />
      <AdModal game={game} />
      <GameTop
        confirmExit={confirmExit}
        points={game.points}
        maxPoints={level.song.length}
        isHighscore={isHighscore}
        flashHighscore={flashHighscore}
        mode={game.mode}
      />
      <ButtonGrid
        animate
        colorScheme={level.colorScheme}
        size={level.size}
        active={game.active}
        disabled={game.mode !== 'repeat'}
        onClick={game.onInput}
      />
      <GameBottom level={level} mode={game.mode} onRestart={game.onRestart} />
    </PageWithBackground>
  )
}
