import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
  PageBottom,
  PageHeader,
  PageTop,
  PageWithBackground,
} from '../../components'
import { i18n } from '../../i18n'
import { LanguageButton } from './LanguageButton'

export function LanguageSelect() {
  const { t } = useTranslation()
  const history = useHistory()

  async function onSelect(language: string) {
    await i18n.changeLanguage(language)
    history.goBack()
  }

  return (
    <PageWithBackground>
      <PageTop>
        <PageHeader title={t('menu.changeLanguage')} />
      </PageTop>
      <Languages>
        <LanguageButton language="de" onSelect={onSelect}>
          Deutsch
        </LanguageButton>
        <LanguageButton language="en" onSelect={onSelect}>
          English
        </LanguageButton>
        <LanguageButton language="es" onSelect={onSelect}>
          Español
        </LanguageButton>
        <LanguageButton language="pl" onSelect={onSelect}>
          Polski
        </LanguageButton>
        <LanguageButton language="ru" onSelect={onSelect}>
          Русский
        </LanguageButton>
      </Languages>
      <PageBottom />
    </PageWithBackground>
  )
}

const Languages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
