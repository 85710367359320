import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 70 },
  `
    8E2+8E3 16B5+16G6+8G3+8B3 16G5+16E6 8B5+8G6+8E3 8A5+8Gb6+8A3+8C4
    8Gb5+8Eb6+8E3 8G5+8E6+8Bb3+8Db4 16F5+16Ab5+16D6+8E3 16F5+16Ab5+16D6 8F5+8Ab5+8D6+8B3+8D4
    16E5+16G5+16Bb5+16Db6+8E3 16E5+16G5+16Bb5+16Db6 8E5+8G5+8Bb5+8Db6+8Db4+8E4 16Eb5+16Gb5+16A5+16C6+8E3 16Eb5+16Gb5+16A5+16C6 8Eb5+8Gb5+8A5+8C6+8Eb4+8Gb4
    16G5+16B5+8E4+8G4 16E6 16A5+16C6+8E4+8Gb4+8A4 16E6 8G5+8B5+8E4+8G4 32E3 32D3 32C3 32B2
    8Bb2
  `
)
