import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const korobeiniki: Level = {
  id: '4208fddc-45f6-44f8-a18d-476ca8449707',
  title: (t) => t('songs.korobeiniki'),
  artist: (t) => t('artists.traditional'),
  colorScheme: makeScheme('#a91622', '#80c3d7', '#d31122', '#908ae2'),
  color: 'rgb(134, 129, 190)',
  background,
  size: 16,
  song: song,
}
