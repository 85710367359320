import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 110 },
  `
    4B4+1G3+1B3+1D4 4B4 4C5 4D5
    4D5+1Gb3+1A3+1D4 4C5 4B4 4A4
    4G4+1G3+1B3+1D4 4G4 4A4 4B4
    4.B4+2G3+2B3+2D4 8A4 2A4+2Gb3+2A3+2D4
    4B4+1G3+1B3+1D4 4B4 4C5 4D5
    4D5+1Gb3+1A3+1D4 4C5 4B4 4A4
    4G4+1G3+1B3+1D4 4G4 4A4 4B4
    4.A4+2Gb3+2A3+2D4 8G4 2G4+2G3+2B3+2D4
    4A4+2D2 4A4 4B4+2D3+2G3+2B3 4G4
    4A4+2D2 8B4 8C5 4B4+2D3+2G3+2B3 4G4
    4A4+2D2 8B4 8C5 4B4+2D3+2G3+2B3 4A4
    4G4+2Db3 4A4 2D4+2D3+2Gb3+2A3
    4B4+1G3+1B3+1D4 4B4 4C5 4D5
    4D5+1Gb3+1A3+1D4 4C5 4B4 4A4
    4G4+1G3+1B3+1D4 4G4 4A4 4B4
    4.A4+2Gb3+2A3+2D4 8G4 2G4+2G3+2B3+2D4
    1G4+1G3+1B3+1D4
  `
)
