import { createContext, ReactNode, useMemo } from 'react'
import { useStorage } from '../hooks'

type Scores = Record<string, number | undefined>
interface ScoreContextValue {
  scores: Scores
  setScore(id: string, score: number): void
}

export const ScoreContext = createContext<ScoreContextValue>({
  scores: {},
  setScore: () => undefined,
})

interface Props {
  children: ReactNode
}

export function ScoreProvider({ children }: Props) {
  const [scores, setScores] = useStorage<Scores>('scores', {})

  const value = useMemo(
    () => ({
      scores,
      setScore(id: string, value: number) {
        setScores({ ...scores, [id]: value })
      },
    }),
    [scores, setScores]
  )

  return <ScoreContext.Provider value={value} children={children} />
}
