import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 80 },
  `
    2B2+2Gb5 8B4+2E3 8Db5 8D5 8E5
    4.Gb5+2B2 8D5 4.Gb5+2B2 8D5
    4.Gb5+2B2 8B4 8D5+2G3 8B4 8G4 8D5
    2B2+2B4
  `
)
