import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 130 },
  `
    4C4 4C4 4E4 4G4
    4G4+4C3 8E3+8G3 8p 4G5+8E3+8G3
    4G5+4C3 8E3+8G3 8p 4E5+8E3+8G3
    4E5+4C3 8E3+8G3 8p 4C4+8E3+8G3
    4C4+4C3 4E4+8E3+8G3 4G4+8E3+8G3
    4G4+4D3 8F3+8G3 8p 4G5+8F3+8G3
    4G5+4D3 8F3+8G3 8p 4F5+8F3+8G3
    4F5+4D3 8F3+8G3 8p 4B3+8F3+8G3
    4B3+4D3 4D4+8F3+8G3 4A4+8F3+8G3
    4A4+4D3 8F3+8G3 8p 4A5+8F3+8G3
    4A5+4G2 8F3+8G3 8p 4F5+8F3+8G3
    4F5+4D3 8F3+8G3
  `
)
