import { useEffect, useMemo, useState } from 'react'
import { Plugins } from '@capacitor/core'

const Storage = Plugins.Storage

export function useStorage<T>(key: string, fallback: T) {
  const [item, setItem] = useState<{ value: T } | undefined>()

  useEffect(() => {
    setItem(undefined)
    let cancelled = false
    Storage.get({ key }).then(({ value }) => {
      if (!cancelled && value) {
        setItem({ value: JSON.parse(value) })
      }
    })
    return () => {
      cancelled = true
    }
  }, [key])

  const setValue = useMemo(
    () => (value: T) => {
      Storage.set({ key, value: JSON.stringify(value) })
      setItem({ value })
    },
    []
  )

  return [item ? item.value : fallback, setValue] as const
}
