import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 96 },
  `
    8B3+4B1 8Db4 8D4+4Gb2 8E4 8Gb4+4B1 8D4 4Gb2+4Gb4
    8F4+4B1 8Db4 4Gb2+4F4 8E4+4B1 8C4 4Gb2+4E4
    8B3+4B1 8Db4 8D4+4Gb2 8E4 8Gb4+4B1 8D4 8Gb4+4Gb2 8B4
    8A4+4D2 8Gb4 8D4+4A2 8Gb4 4D2+2A4
  `
)
