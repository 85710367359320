import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 125 },
  `
    8E3+4E5 8B3 8E4+8B4 8B3+8C5 8Ab3+4D5 8B3 8E3+8C5 8B3+8B4
    8A2+4A4 8E3 8A3+8A4 8E3+8C5 8C3+4E5 8E3 8A2+8D5 8E3+8C5
    8E3+4B4 8B3 8E4+8B4 8B3+8C5 8Ab3+4D5 8B3 8E3+4E5 8B3
    8A2+4C5 8E3 8A3+4A4 8p 2A2+2E3+2A3+2A4
  `
)
