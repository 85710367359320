import { AdPosition, AdSize } from '@capacitor-community/admob'
import { Capacitor, PluginListenerHandle } from '@capacitor/core'
import { config } from '../../config'
import { AdMob } from './AdMob'

export async function initAds() {
  const platform = Capacitor.getPlatform()
  if (platform === 'web' || !config.initAds) {
    return false
  }

  const promise = AdMob.initialize({})

  if (config.showBannerAd) {
    AdMob.showBanner({
      adId: config.bannerAdId,
      adSize: AdSize.BANNER,
      position: AdPosition.BOTTOM_CENTER,
      margin: 0,
    })
  }

  AdMob.addListener(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    'onAdSize' as any,
    (size: { width: number; height: number }) => {
      document.documentElement.style.setProperty(
        '--banner-height',
        `${size.height}px`
      )
    }
  )

  return promise.then((x) => x.value)
}

export async function prepareRewardAd() {
  return new Promise<boolean>((resolve) => {
    const handles: PluginListenerHandle[] = []
    const clear = () => handles.forEach((h) => h.remove())
    handles.push(
      AdMob.addListener('onRewardedVideoAdLoaded', () => {
        clear()
        resolve(true)
      })
    )
    handles.push(
      AdMob.addListener('onRewardedVideoAdFailedToLoad', () => {
        clear()
        resolve(false)
      })
    )
    AdMob.prepareRewardVideoAd({
      adId: config.rewardAdId,
    })
  })
}

export async function showRewardAd() {
  return new Promise<boolean>((resolve) => {
    const handles: PluginListenerHandle[] = []
    const clear = () => handles.forEach((h) => h.remove())
    handles.push(
      AdMob.addListener('onRewarded', () => {
        clear()
        resolve(true)
      })
    )
    handles.push(
      AdMob.addListener('onRewardedVideoAdLeftApplication', () => {
        clear()
        resolve(false)
      })
    )
    handles.push(
      AdMob.addListener('onRewardedVideoAdClosed', () => {
        clear()
        resolve(false)
      })
    )
    AdMob.showRewardVideoAd()
  })
}
