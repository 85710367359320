import { Song } from './Song'

const song = Song.fromText(
  { bpm: 120, sustain: true },
  `
    4C3+4E3+4G3
    4C3+4E4+4G4
    8E3+8G4+8C5
    8E3+8G4+8C5
    8E3+8G4+8C5
  `,
  [1, 1, 1, 1, 1]
)

export function playVictorySound() {
  song.play()
}
