import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 192 },
  `
    4E4+1C3+1G3 4E4 2E4
    4E4+1C3+1G3 4E4 2E4
    4E4+2C3+2G3 4G4 4C4+2C3+2G3 4D4
    1C3+1G3+1E4
    4F4+1C3+1A3 4F4 2F4
    4E4+1C3+1G3 4E4 2E4
    4E4+1C3+1G3 4D4 4D4 4E4
    2D4+1B2+1G3 2G4
  `
)
