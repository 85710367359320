import { useMemo } from 'react'
import styled from 'styled-components'
import { ColorScheme } from '../../core'
import { getButtons } from './buttons'
import { IconButton } from './IconButton'
import { useEntered } from './useEntered'
import { useGridSize } from './useGridSize'

interface Props {
  size: 4 | 9 | 16 | 25
  colorScheme: ColorScheme
  width?: number
  animate?: boolean
  disabled?: boolean
  onClick?: (index: number) => void
  active?: number
}

export function ButtonGrid({
  size,
  colorScheme,
  width,
  animate,
  disabled,
  onClick,
  active,
}: Props) {
  const buttons = useMemo(() => getButtons(colorScheme, size), [size])
  const entered = useEntered(size, !!animate)
  const { cellCount, cellSize, gapSize } = useGridSize(size, width)

  return (
    <Grid repeat={cellCount} cell={cellSize} gap={gapSize}>
      {buttons.map(({ shape, colorTop, colorLight, colorSide }, index) => (
        <IconButton
          key={index}
          size={cellSize}
          shape={shape}
          colorTop={colorTop}
          colorSide={colorSide}
          colorLight={colorLight}
          active={index === active}
          entering={index >= entered}
          disabled={disabled}
          onClick={() => onClick?.(index)}
        />
      ))}
    </Grid>
  )
}

const Grid = styled.div<{ repeat: number; cell: number; gap: number }>`
  display: grid;
  grid-template-rows: ${({ cell: c, repeat: r }) => `repeat(${r}, ${c}px)`};
  grid-template-columns: ${({ cell: c, repeat: r }) => `repeat(${r}, ${c}px)`};
  row-gap: ${({ gap }) => gap}px;
  column-gap: ${({ gap }) => gap}px;
`
