import { Color } from './Color'

export type ColorScheme = { color: Color; x: number; y: number }[]

export function makeScheme(
  topLeft: Color | string,
  topRight: Color | string,
  bottomLeft: Color | string,
  bottomRight: Color | string
): ColorScheme {
  return [
    { color: toColor(topLeft), x: 0, y: 0 },
    { color: toColor(topRight), x: 1, y: 0 },
    { color: toColor(bottomLeft), x: 0, y: 1 },
    { color: toColor(bottomRight), x: 1, y: 1 },
  ]
}

function toColor(c: Color | string) {
  return c instanceof Color ? c : Color.fromHex(c)
}
