import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ConfirmModal, Icon } from '../../components'
import { useAd } from '../../hooks'
import { Game } from './useGame'

interface Props {
  game: Game
}

export function AdModal({ game }: Props) {
  const { t } = useTranslation()
  const { ready, showAd } = useAd()
  const [adModalOpen, setAdModalOpen] = useState(false)
  const [continueOpen, setContinueOpen] = useState(false)
  const [once, setOnce] = useState(false)

  useEffect(() => {
    if (game.mode === 'loss' && game.points > 0 && !once && ready) {
      setOnce(true)
      const timeout = setTimeout(() => setAdModalOpen(true), 500)
      return () => clearTimeout(timeout)
    }
  }, [ready, game.mode, game.points])

  useEffect(() => {
    setOnce(false)
  }, [game.level])

  function closeAdModal() {
    setAdModalOpen(false)
  }

  async function onShow() {
    setAdModalOpen(false)
    const reward = await showAd()
    if (reward) {
      setContinueOpen(true)
    }
  }

  function onContinue() {
    setContinueOpen(false)
    game.onContinue()
  }

  return (
    <>
      <ConfirmModal
        open={adModalOpen}
        onClose={closeAdModal}
        onConfirm={onShow}
        cancelText={t('modal.noThanks')}
        confirmText={
          <>
            {t('modal.watchAd')} <Icon inline shape="ad" />
          </>
        }
      >
        {t('modal.watchAdQuestion')}
      </ConfirmModal>
      <ConfirmModal
        open={continueOpen}
        onlyConfirm
        onClose={onContinue}
        onConfirm={onContinue}
        confirmText={t('modal.continue')}
      >
        {t('modal.rewardSuccess')}
      </ConfirmModal>
    </>
  )
}
