import { Plugins } from '@capacitor/core'
import { useEffect } from 'react'

const App = Plugins.App

interface Listener {
  priority: number
  callback: () => void
}

const listeners: Listener[] = []

function addListener(priority: number, callback: () => void) {
  const listener = { priority, callback }
  listeners.push(listener)
  return () => {
    listeners.splice(listeners.indexOf(listener), 1)
  }
}

function onBackButtonPressed() {
  const maxPriority = listeners.reduce(
    (priority, listener) => Math.max(priority, listener.priority),
    0
  )
  const toCall = listeners.filter((x) => x.priority === maxPriority)
  for (const listener of toCall) {
    try {
      listener.callback()
    } catch (e) {
      console.error(e)
    }
  }
}

App.addListener('backButton', () => onBackButtonPressed())

export function useBackButton(priority: number | false, callback: () => void) {
  useEffect(() => {
    if (typeof priority === 'number') {
      return addListener(priority, callback)
    }
  }, [priority, callback])
}

Reflect.set(window, '__DEV__BACK__', () => onBackButtonPressed())

export const BackPriority = {
  Disable: false,
  Base: 0,
  App: 1,
  Modal: 100,
} as const
