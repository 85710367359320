import { ComponentProps } from 'react'
import styled from 'styled-components'
import { PageTop } from '../../components'
import { GameViewHeader } from './GameViewHeader'
import { GameMode } from './GameMode'
import { Mode } from './useGame'

interface Props extends ComponentProps<typeof GameViewHeader> {
  mode: Mode
}

export function GameTop({ mode, ...props }: Props) {
  return (
    <Top>
      <GameViewHeader {...props} />
      <GameMode mode={mode} />
    </Top>
  )
}

const Top = styled(PageTop)`
  display: flex;
  flex-direction: column;
`
