import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Icon, Points } from '../../components'
import { Colors } from '../../constants'
import { Level } from '../../core'
import { useScore } from '../../hooks'

interface Props {
  levels: (Level | undefined)[]
}

export function LevelList({ levels }: Props) {
  return (
    <>
      {levels.map((level, i) =>
        level ? (
          <LevelItem level={level} key={i} />
        ) : (
          <Soon key={i}>Coming Soon</Soon>
        )
      )}
    </>
  )
}

const Soon = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: ${Colors.LightBackground};
  padding: 10px 16px;
  margin-top: 8px;
  font-style: italic;
  height: 54px;
`

function LevelItem({ level }: { level: Level }) {
  const { t } = useTranslation()
  const [score] = useScore(level.id)
  const crown = score === level.song.length

  return (
    <LevelLink to={`levels/${level.id}`}>
      {crown && <Crown />}
      <LevelTitle>{level.title(t)}</LevelTitle>
      <LevelArtist>{level.artist(t)}</LevelArtist>
      <StyledPoints points={score} maxPoints={level.song.length} />
    </LevelLink>
  )
}

const LevelLink = styled(Link)`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: 'title points' 'artist points';
  column-gap: 16px;
  text-decoration: none;
  color: inherit;
  position: relative;
  border-radius: 8px;
  background-color: ${Colors.LightBackground};
  padding: 10px 16px;
  margin-top: 8px;
`

const LevelTitle = styled.div`
  grid-area: title;
  line-height: 1.5;
  font-size: 18px;
  margin-top: -4px;
  margin-bottom: -3px;
  font-weight: bold;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LevelArtist = styled.div`
  grid-area: artist;
  line-height: 1.5;
  margin-top: -3px;
  margin-bottom: -4px;
  font-size: 14px;
  font-style: italic;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StyledPoints = styled(Points)`
  grid-area: points;
`

const Crown = styled(Icon).attrs({ shape: 'crown' })`
  position: absolute;
  top: -12px;
  left: -4px;
  color: ${Colors.Yellow};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));
  transform: rotate(-30deg);
`
