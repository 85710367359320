export function preventZoom() {
  window.addEventListener(
    'touchmove',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (event: any) {
      if (event.scale !== 1) {
        event.preventDefault()
      }
    },
    { passive: false }
  )
}
