import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 120 },
  `
    4G3+4G4 8p 8D3+8D4 4G3+4G4 8p 8D3+8D4
    8G3+8G4 8D3+8D4 8G3+8G4 8B3+8B4 4D4+4D5 4p
    4C4+4C5 8p 8A3+8A4 4C4+4C5 8p 8A3+8A4
    8C4+8C5 8A3+8A4 8Gb3+8Gb4 8A3+8A4 4D3+4D4 4p
  `
)
