import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const canon: Level = {
  id: '6502f8f4-f6fe-4c35-867b-d135141af8d2',
  title: (t) => t('songs.canon'),
  artist: (t) => t('artists.pachelbel'),
  colorScheme: makeScheme('#56b8e1', '#f8ed62', '#e9d700', '#dab600'),
  color: 'rgb(102, 145, 183)',
  background,
  size: 16,
  song: song,
}
