import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const greensleeves: Level = {
  id: '9f949400-4bdd-4006-8b45-73f96edefb92',
  title: (t) => t('songs.greensleeves'),
  artist: (t) => t('artists.traditional'),
  colorScheme: makeScheme('#809c13', '#abc32f', '#dcdc8e', '#b5e550'),
  color: 'rgb(124, 171, 114)',
  background,
  size: 16,
  song: song,
}
