import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const passacaglia: Level = {
  id: 'dfe435d7-46c7-4ceb-952c-7256ca85f735',
  title: (t) => t('songs.passacaglia'),
  artist: (t) => t('artists.halvorsen'),
  colorScheme: makeScheme('#bf86a4', '#e7bcad', '#8fc6bc', '#d5f4b1'),
  color: 'rgb(173, 125, 117)',
  background,
  size: 25,
  song: song,
}
