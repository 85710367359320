import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const carol: Level = {
  id: '1e4cf6ec-34ab-46e4-b2b0-05de85d9c06f',
  title: (t) => t('songs.carol'),
  artist: (t) => t('artists.leontovych'),
  colorScheme: makeScheme('#006b3c', '#009252', '#6b9d7f', '#56e78e'),
  color: 'rgb(143, 68, 75)',
  background,
  size: 9,
  song: song,
}
