import { ButtonGrid, PageWithBackground } from '../../components'
import { entertainer } from '../../core/levels/entertainer'
import { GameBottom } from '../GameView/GameBottom'
import { GameTop } from '../GameView/GameTop'
import { useNextScreenshot } from './useNextScreenshot'

const NO_OP = () => undefined

export function FirstScreenshot() {
  const level = entertainer

  useNextScreenshot(2)

  return (
    <PageWithBackground color={level.color} background={level.background}>
      <GameTop
        confirmExit={false}
        points={10}
        maxPoints={level.song.length}
        isHighscore={false}
        flashHighscore={false}
        mode={'repeat'}
      />
      <ButtonGrid
        colorScheme={level.colorScheme}
        size={level.size}
        active={5}
        disabled={true}
        onClick={NO_OP}
      />
      <GameBottom level={level} mode={'observe'} onRestart={NO_OP} />
    </PageWithBackground>
  )
}
