import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const entertainer: Level = {
  id: 'f50f53c9-c195-433e-b562-eed8513d1dfa',
  title: (t) => t('songs.entertainer'),
  artist: (t) => t('artists.joplin'),
  colorScheme: makeScheme('#f6412d', '#ff9800', '#ffc100', '#ffec19'),
  color: 'rgb(160, 153, 100)',
  background,
  size: 9,
  song: song,
}
