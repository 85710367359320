import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { config } from '../config'
import germanTranslation from './translations/de.json'
import englishTranslation from './translations/en.json'
import spanishTranslation from './translations/es.json'
import polishTranslation from './translations/pl.json'
import russianTranslation from './translations/ru.json'

export function initI18n() {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      fallbackLng: 'en',
      resources: {
        en: { translation: englishTranslation },
        pl: { translation: polishTranslation },
        ru: { translation: russianTranslation },
        es: { translation: spanishTranslation },
        de: { translation: germanTranslation },
      },
      interpolation: {
        escapeValue: false,
      },
    })

  if (config.languageOverride !== false) {
    i18n.changeLanguage(config.languageOverride)
  }
}

export { i18n }
