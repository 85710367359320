import { Level } from '../Level'
import { bellaCiao } from './bellaCiao'
import { blueDanube } from './blueDanube'
import { canon } from './canon'
import { carol } from './carol'
import { entertainer } from './entertainer'
import { furElise } from './furElise'
import { greensleeves } from './greensleeves'
import { jingleBells } from './jingleBells'
import { korobeiniki } from './korobeiniki'
import { majorScale } from './majorScale'
import { marcheTurque } from './marcheTurque'
import { mountainKing } from './mountainKing'
import { nachtmusik } from './nachtmusik'
import { nutcracker } from './nutcracker'
import { odeToJoy } from './odeToJoy'
import { passacaglia } from './passacaglia'
import { pinata } from './pinata'
import { swanLake } from './swanLake'
import { symphony5 } from './symphony5'
import { twinkle } from './twinkle'

export const levels = {
  beginner: [majorScale, symphony5, twinkle, swanLake, nachtmusik],
  intermediate: [carol, entertainer, jingleBells, pinata, nutcracker],
  expert: [mountainKing, greensleeves, canon, korobeiniki, marcheTurque],
  master: [bellaCiao, passacaglia, furElise, blueDanube, odeToJoy],
}

export const levelsById: Record<string, Level> = {}

export const allLevels = [
  ...levels.beginner,
  ...levels.intermediate,
  ...levels.expert,
  ...levels.master,
]
for (const level of allLevels) {
  levelsById[level.id] = level
}
