import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Colors } from '../constants'
import { Modal } from './Modal'

interface Props {
  open: boolean
  onClose: () => void
  onCancel?: () => void
  onConfirm: () => void
  onlyConfirm?: boolean
  children: ReactNode
  className?: string
  cancelText?: string | ReactNode
  confirmText?: string | ReactNode
}

export function ConfirmModal(props: Props) {
  const { t } = useTranslation()

  return (
    <StyledModal open={props.open} onClose={props.onClose}>
      <Question>{props.children}</Question>
      <Buttons className={props.onlyConfirm ? 'single' : ''}>
        {!props.onlyConfirm && (
          <Button onClick={props.onCancel ?? props.onClose}>
            {props.cancelText || t('modal.cancel')}
          </Button>
        )}
        <ConfirmButton onClick={props.onConfirm}>
          {props.confirmText || t('modal.confirm')}
        </ConfirmButton>
      </Buttons>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  border-radius: 22px;
  padding: 16px;
`

const Question = styled.div`
  text-align: center;
  margin-bottom: 16px;
`

const Buttons = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
  max-width: 400px;
  margin: 0 auto;

  &.single {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const Button = styled.button`
  border: none;
  outline: none;
  height: 44px;
  font-weight: bold;
  background-color: transparent;
  color: inherit;
  border-radius: 22px;
  padding: 0 16px;
`

const ConfirmButton = styled(Button)`
  background-color: ${Colors.LightBackground};
`
