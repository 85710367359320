import { Howl } from 'howler'

export const notes: Record<string, Howl> = {}

const names = ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B']

export async function initHowler() {
  const promises: Promise<void>[] = []
  for (let n = 0x15; n <= 0x6c; n++) {
    const octave = ((n - 12) / 12) >> 0
    const key = names[n % 12] + octave

    const sound = new Howl({ src: [`/assets/acoustic-grand-piano/${key}.mp3`] })
    promises.push(
      new Promise((resolve, reject) => {
        sound.on('load', resolve)
        sound.on('loaderror', reject)
      })
    )
    notes[key] = sound
  }
  return Promise.all(promises)
}

export function playNote(note: string) {
  const id = notes[note].play()
  return () => notes[note].fade(1, 0, 50, id)
}
