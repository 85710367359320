import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const majorScale: Level = {
  id: 'fe33290b-7632-4b61-985f-5785f5155852',
  title: (t) => t('songs.majorScale'),
  artist: (t) => t('level.startHere'),
  colorScheme: makeScheme('#aa8773', '#e45932', '#98251e', '#f3a93e'),
  color: 'rgb(130, 127, 105)',
  background,
  size: 4,
  song: song,
}
