import { useEffect, useState } from 'react'
import { useScore } from '../../hooks'
import { Game } from './useGame'

export function useHighscore(game: Game, levelId: string) {
  const [highscore, setHighscore] = useScore(levelId)
  const [flashHighscore, setFlashHighscore] = useState(false)

  useEffect(() => {
    if (
      game.mode === 'win' ||
      (game.mode === 'loss' && game.points > highscore)
    ) {
      if (game.points > highscore) {
        setFlashHighscore(true)
      }
      setHighscore(game.points)
    }
  }, [highscore, setHighscore, game.mode, game.points])

  useEffect(() => {
    if (flashHighscore) {
      const timeout = setTimeout(() => setFlashHighscore(false), 2000)
      return () => clearTimeout(timeout)
    }
  }, [flashHighscore])

  return { highscore, flashHighscore }
}
