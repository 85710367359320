import { makeSvgIcon } from '../makeSvgIcon'

export const clubs = makeSvgIcon(
  <>
    <circle cx="6" cy="14" r="5" />
    <circle cx="12" cy="6" r="5" />
    <circle cx="18" cy="14" r="5" />
    <path d="M15 23H9C11 21 13 16 6 14L12 6L18 14C11 16 13 21 15 23Z" />
  </>
)
