import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const pinata: Level = {
  id: 'd61eead8-5d80-40e1-a881-4035bd52f5b0',
  title: (t) => t('songs.pinata'),
  artist: (t) => t('artists.traditional'),
  colorScheme: makeScheme('#9063e9', '#6fbefb', '#61ef94', '#fff661'),
  color: 'rgb(152, 105, 151)',
  background,
  size: 9,
  song: song,
}
