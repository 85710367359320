import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 110 },
  `
    8E5 8Eb5
    8E5 8Eb5 8E5 8B4 8D5 8C5
    4A4+4.A2 8p 8C4 8E4 8A4
    4B4+4.E3 8p 8E4 8Ab4 8B4
    4C5+4.A2 8p 8E4 8E5 8Eb5
    8E5 8Eb5 8E5 8B4 8D5 8C5
    4A4+4.A2 8p 8C4 8E4 8A4
    4B4+4.E3 8p 8E4 8C5 8B4
    4A4+4.A2
  `
)
