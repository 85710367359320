import { playNote } from './howler'

export function playFalseSound() {
  const notes = chords[Math.floor(Math.random() * chords.length)]
  for (const note of notes) {
    const stop = playNote(note)
    setTimeout(stop, 1000)
  }
}

const chords = [
  ['Db5', 'Eb5'],
  ['F4', 'Gb4'],
  ['C4', 'B4', 'Bb4'],
]
