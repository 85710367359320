import { createGlobalStyle } from 'styled-components'
import { Colors } from '../constants'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  html,
  body,
  #app {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overscroll-behavior: none;
    width: 100%;
    height: 100%;
    background-color: ${Colors.Background};
    color: ${Colors.Text};
    font-size: 18px;
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
  }

  button {
    font-family: inherit;
    font-size: 1rem;
  }
`
