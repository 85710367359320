const PRODUCTION = true
const SHOW_BANNER = false
const SCREENSHOT_MODE = false
const DEBUG = false

export interface Config {
  bannerAdId: string
  rewardAdId: string
  screenshotMode: boolean
  useRealRouter: boolean
  debug: boolean
  initAds: boolean
  showBannerAd: boolean
  languageOverride: false | string
}

function getTestConfig(): Config {
  return {
    bannerAdId: 'ca-app-pub-3940256099942544/6300978111',
    rewardAdId: 'ca-app-pub-3940256099942544/5224354917',
    screenshotMode: SCREENSHOT_MODE,
    useRealRouter: SCREENSHOT_MODE,
    debug: DEBUG,
    initAds: !SCREENSHOT_MODE && !DEBUG,
    showBannerAd: SHOW_BANNER,
    languageOverride: 'en',
  }
}

function getProductionConfig(): Config {
  return {
    bannerAdId: 'ca-app-pub-9222602719540822/9892310426',
    rewardAdId: 'ca-app-pub-9222602719540822/5452719571',
    screenshotMode: false,
    useRealRouter: false,
    debug: DEBUG,
    initAds: true,
    showBannerAd: SHOW_BANNER,
    languageOverride: false,
  }
}

export const config = PRODUCTION ? getProductionConfig() : getTestConfig()
