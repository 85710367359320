import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 110 },
  `
    8A4+8A5 8B4+8B5
    8A3+4Db5+4Db6 8A3 8A4+8A5+8A3 8B4+8B5+8A3
    8A3+8Db5+8Db6 8B4+8B5+8A3 8A4+8A5+8A3 8Ab4+8Ab5+8A3
    8Gb4+8Gb5+8D3 8Ab4+8Ab5+8D3 8A4+8A5+8Eb3 8B4+8B5+8Eb3
    8Ab4+8Ab5+8E3 8E4+8E5+8E3 8A4+8A5+8E3 8B4+8B5+8E3
    8A3+4Db5+4Db6 8A3 8A4+8A5+8A3 8B4+8B5+8A3
    8A3+8Db5+8Db6 8B4+8B5+8A3 8A4+8A5+8A3 8Ab4+8Ab5+8A3
    8Gb4+8Gb5+8D3 8B4+8B5+8D3 8Ab4+8Ab5+8E3 8E4+8E5+8E3
    4A4+4A5+4A2
  `
)
