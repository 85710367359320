import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const marcheTurque: Level = {
  id: 'b9d95c4b-950c-424f-9fd1-eccc7f37a49f',
  title: (t) => t('songs.marcheTurque'),
  artist: (t) => t('artists.mozart'),
  colorScheme: makeScheme('#9c4f20', '#daa46d', '#d0b892', '#5c9675'),
  color: 'rgb(175, 113, 121)',
  background,
  size: 16,
  song: song,
}
