import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const nutcracker: Level = {
  id: '598ff853-f885-4988-afff-737ebbb2a956',
  title: (t) => t('songs.nutcracker'),
  artist: (t) => t('artists.tchaikovsky'),
  colorScheme: makeScheme('#800727', '#c20e2c', '#941512', '#db1d34'),
  color: 'rgb(120, 120, 120)',
  background,
  size: 9,
  song: song,
}
