import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { ConfirmModal, PageHeader, Points } from '../../components'
import { TEXT_SHADOW } from '../../constants'

interface Props {
  points: number
  maxPoints: number
  isHighscore?: boolean
  flashHighscore?: boolean
  confirmExit: boolean
}

export function GameViewHeader(props: Props) {
  const { t } = useTranslation()
  const [backModalOpen, setBackModalOpen] = useState(false)
  const history = useHistory()

  function onBack() {
    if (props.confirmExit) {
      setBackModalOpen(true)
    } else {
      history.goBack()
    }
  }

  return (
    <>
      <HeaderWithShadow onBack={onBack}>
        <Points
          points={props.points}
          maxPoints={props.maxPoints}
          isHighscore={props.isHighscore}
          flashHighscore={props.flashHighscore}
        />
      </HeaderWithShadow>
      <ConfirmModal
        open={backModalOpen}
        onClose={() => setBackModalOpen(false)}
        onConfirm={() => history.goBack()}
        confirmText={t('modal.quit')}
      >
        {t('modal.quitLevel')}
      </ConfirmModal>
    </>
  )
}

const HeaderWithShadow = styled(PageHeader)`
  filter: ${TEXT_SHADOW};
`
