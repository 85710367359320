import { Capacitor, Plugins } from '@capacitor/core'

export async function initNativeSettings() {
  const platform = Capacitor.getPlatform()
  if (platform === 'android') {
    Plugins.StatusBar.setOverlaysWebView({ overlay: true })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Insets = (Plugins as any).InsetsPlugin
    const insets: { top: number; bottom: number } = await Insets.getInsets()
    // if no inset then account for status bar
    const top = insets.top || 25
    document.documentElement.style.setProperty('--inset-top', `${top}px`)
    const bottom = insets.bottom || 0
    document.documentElement.style.setProperty('--inset-bottom', `${bottom}px`)
  }
}
