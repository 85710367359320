import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 93 },
  `
    8G4+4C3 8G4 8G4 8E4 4A4+4F3 4A4
    8B4+4G3 8B4 8B4 8G4 4C5+4C3 4C5
    8G4 8G4 8G4 8E4 4A4+4F3 4A4
    8B4+4G3 8B4 8B4 8G4 4C5+4C3 4C5
  `
)
