import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 130 },
  `
    8A2 8A3 8E4 8C4 8A4 8C4 8E4 8C4
    8A2 8A3 8E4 8C4 8A4 8C4 8E4 8C4
    8A2+8C5 8A3+8C6 8E4+8B5 8C4+8C6 8A4+8A5 8C4+8C6 8E4+8G5 8C4+8C6
    8D2+8F5 8D3+8C6 8A3+8E5 8F3+8C6 8D4+8D5 8F3+8C6 8A3+8C5 8F3+8C6
  `
)
