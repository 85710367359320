import { ReactNode } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { BackButton } from './BackButton'

interface Props {
  onBack?: () => void
  title?: string
  children?: ReactNode
  className?: string
}

export function PageHeader({ onBack, title, children, className }: Props) {
  const history = useHistory()
  const goBack = () => history.goBack()

  return (
    <Wrapper className={className}>
      <BackButton onClick={onBack || goBack} />
      {title && <PageTitle>{title}</PageTitle>}
      <div>{children}</div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`

const PageTitle = styled.div`
  position: absolute;
  left: 0;
  pointer-events: none;
  text-align: center;
  width: 100%;
  line-height: 1;
  font-weight: bold;
`
