import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 120 },
  `
    8B3 8E4 8Gb4
    8E2+8G4 8E3+8G3+4.E4 8B1 8E3+8G3 8E2 8E3+8G3+8B3 8B1+8E4 8E3+8G3+8Gb4
    8E2+8G4 8E3+8G3+4.E4 8B1 8E3+8G3 8E2 8E3+8G3+8B3 8B1+8E4 8E3+8G3+8Gb4
    8E2+4E4+4G4 8E3+8G3 8B1+8Gb4 8E3+8G3+8E4 8D2+4D4+4G4 8D3+8Gb3 8D2+8Gb4 8D3+8Gb3+8E4
    8C2+4C4+4E4+4B4 8C3+8E3 8C2+4C4+4E4+4B4 8C3+8E3 8B1+4B3+4Eb4+4B4
  `
)
