import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { Icon, PageBottom } from '../../components'
import { TEXT_SHADOW } from '../../constants'
import { allLevels, Level } from '../../core'
import { Mode } from './useGame'

interface Props {
  level: Level
  mode: Mode
  onRestart: () => void
}

export function GameBottom({ level, mode, onRestart }: Props) {
  const { t } = useTranslation()
  const history = useHistory()

  const levelIndex = allLevels.findIndex((l) => l === level)
  function goToNextLevel() {
    history.goBack()
    history.push(`/levels/${allLevels[levelIndex + 1].id}`)
  }

  if (levelIndex !== allLevels.length - 1 && mode === 'win') {
    return (
      <Wrapper>
        <NextButton onClick={goToNextLevel}>
          {t('level.next')} <Icon shape="right" inline />
        </NextButton>
      </Wrapper>
    )
  } else if (mode === 'loss') {
    return (
      <Wrapper>
        <RetryButton onClick={onRestart}>
          <Icon shape="retry" size={64} />
        </RetryButton>
      </Wrapper>
    )
  } else {
    return (
      <Wrapper>
        <LevelTitle>{level.title(t)}</LevelTitle>
        <LevelArtist>{level.artist(t)}</LevelArtist>
      </Wrapper>
    )
  }
}

const Wrapper = styled(PageBottom)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  filter: ${TEXT_SHADOW};
`

const RetryButton = styled.button`
  padding: 8px;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
`

const NextButton = styled.button`
  padding: 8px;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  line-height: 1;
  font-weight: bold;
  font-size: 28px;
`

const LevelTitle = styled.div`
  line-height: 1;
  font-weight: bold;
  font-size: 28px;
  padding: 0 16px;
  margin-bottom: 16px;
`

const LevelArtist = styled.div`
  line-height: 1;
  font-size: 24px;
  font-style: italic;
`
