import styled from 'styled-components'
import { Icon } from './icons'
import { BackPriority, useBackButton } from '../hooks'

interface Props {
  onClick: () => void
}

export function BackButton({ onClick }: Props) {
  useBackButton(BackPriority.App, onClick)

  return (
    <Button onClick={onClick}>
      <Icon shape="left" />
    </Button>
  )
}

const Button = styled.button`
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  color: inherit;
  padding: 16px;
  margin: -16px;
`
