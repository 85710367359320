import { useEffect, useState } from 'react'
import { playFalseSound, playVictorySound, Song } from '../../core'

export type Mode = 'observe' | 'repeat' | 'win' | 'loss'
export type Game = ReturnType<typeof useGame>

export function useGame(size: number, song: Song) {
  const [mode, setMode] = useState<Mode>('observe')
  const [points, setPoints] = useState(0)
  const [progress, setProgress] = useState(0)
  const [active, setActive] = useState<number | undefined>()
  const [level, setLevel] = useState<number[]>([])

  useEffect(() => {
    setLevel(generateLevel(size, song))
  }, [size, song])

  useEffect(() => {
    if (mode !== 'observe') {
      return
    }

    setProgress(0)
    let index = 0
    let timeout = setTimeout(playNext, points === 0 ? 1500 : 500)
    let timeout2: ReturnType<typeof setTimeout> | undefined
    function playNext() {
      if (index === points + 1) {
        setMode('repeat')
        return
      }
      setActive(level[index])
      const delay = song.playChord(index)
      index += 1
      timeout = setTimeout(playNext, delay)
      timeout2 = setTimeout(() => setActive(undefined), delay * 0.75)
    }

    return () => {
      clearTimeout(timeout)
      if (timeout2) {
        clearTimeout(timeout2)
      }
    }
  }, [mode, level])

  function onInput(index: number) {
    const expected = level[progress]
    if (index === expected) {
      song.playChord(progress)
      if (progress === points) {
        setPoints(points + 1)
        if (progress === level.length - 1) {
          setTimeout(playVictorySound, 500)
          setMode('win')
        } else {
          setMode('observe')
        }
      } else {
        setProgress(progress + 1)
      }
    } else {
      playFalseSound()
      setMode('loss')
    }
  }

  function onRestart() {
    setPoints(0)
    setLevel(generateLevel(size, song))
    setMode('observe')
  }

  function onContinue() {
    setMode('observe')
  }

  return {
    level,
    points,
    mode,
    active,
    onInput,
    onRestart,
    onContinue,
  }
}

function generateLevel(size: number, song: Song) {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      return tryGenerateLevel(size, song)
    } catch {
      continue
    }
  }
}

function tryGenerateLevel(size: number, { pattern }: Song) {
  const remaining = range(size)
  const mapped: Record<number, number | undefined> = {}
  const level: number[] = []
  for (let i = 0; i < pattern.length; i++) {
    const known = mapped[pattern[i]]
    if (known !== undefined) {
      if (known === level[i - 1] && pattern[i] !== pattern[i - 1]) {
        throw new Error('Failed')
      }
      level.push(known)
    } else if (remaining.length !== 0) {
      const index = randInt(remaining.length)
      const chosen = remaining[index]
      mapped[pattern[i]] = chosen
      level.push(chosen)
      remaining.splice(index, 1)
    } else {
      const chosen = (randInt(size - 1) + level[i - 1] + 1) % size
      mapped[pattern[i]] = chosen
      level.push(chosen)
    }
  }
  return level
}

function range(max: number): number[] {
  return new Array(max).fill(0).map((x, i) => i)
}

function randInt(max: number) {
  return Math.floor(Math.random() * max)
}
