import { Color } from './Color'
import { ColorScheme } from './schemes'

function sampleColor(scheme: ColorScheme, x: number, y: number) {
  const colors = scheme.map((c) => ({
    ...c,
    factor: 1 - Math.max(Math.abs(c.x - x), Math.abs(c.y - y)),
  }))
  const total = colors.reduce((total, c) => total + c.factor, 0)
  return colors.reduce(
    (color, c) => color.add(c.color.multiply(c.factor / total)),
    new Color(0, 0, 0)
  )
}

export function colorsAt(scheme: ColorScheme, x: number, y: number) {
  const primary = sampleColor(scheme, x, y)
  return {
    colorTop: primary.toHex(),
    colorSide: primary
      .adjustLightness((l) => Math.max(l * 0.5, l - 0.4))
      .toHex(),
    colorLight: primary
      .adjustLightness((l) => Math.min(l * 1.5, l + 0.2))
      .toHex(),
  }
}
