import { makeSvgIcon } from '../makeSvgIcon'

export const bell = makeSvgIcon(
  <>
    <path d="M12 4C21 4 17 17 21 17V19H3L3 17C7 17 3 4 12 4Z" />
    <circle cx="12" cy="18" r="3" />
    <circle cx="12" cy="18" r="3" />
    <circle cx="12" cy="5" r="2" />
    <circle cx="12" cy="5" r="2" />
  </>
)
