import { ReactNode } from 'react'
import styled from 'styled-components'
import { i18n } from '../../i18n'

interface Props {
  language: string
  onSelect: (language: string) => void
  children: ReactNode
}

export function LanguageButton({ language, onSelect, children }: Props) {
  const selected = language === i18n.language
  return (
    <Button selected={selected} onClick={() => onSelect(language)}>
      {children}
    </Button>
  )
}

const Button = styled.button<{ selected: boolean }>`
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  display: block;
  padding: 8px 16px;
  color: inherit;
  text-decoration: none;
  background: none;
  border: none;
  font-size: inherit;
`
