import { Level } from '../../Level'
import { makeScheme } from '../../schemes'
import background from './background.jpg'
import { song } from './song'

export const mountainKing: Level = {
  id: '23bc0f39-78e6-49bd-acc8-a50120fd9d04',
  title: (t) => t('songs.mountainKing'),
  artist: (t) => t('artists.grieg'),
  colorScheme: makeScheme('#664422', '#987554', '#c2996c', '#dfbb8b'),
  color: 'rgb(226, 187, 152)',
  background,
  size: 16,
  song: song,
}
