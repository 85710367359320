import { Level } from '../../Level'
import background from './background.jpg'
import { makeScheme } from '../../schemes'
import { song } from './song'

export const twinkle: Level = {
  id: '3e728813-c591-4820-8206-7ce8d4b9c49d',
  title: (t) => t('songs.twinkle'),
  artist: (t) => t('artists.traditional'),
  colorScheme: makeScheme('#438cf0', '#335a9b', '#93bee8', '#dcc68c'),
  color: 'rgb(50, 81, 140)',
  background,
  size: 9,
  song: song,
}
