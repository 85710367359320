export class Color {
  readonly r: number
  readonly g: number
  readonly b: number

  constructor(r: number, g: number, b: number) {
    this.r = clamp01(r)
    this.g = clamp01(g)
    this.b = clamp01(b)
  }

  static fromHex(hex: string) {
    return new Color(
      parseInt(hex.substring(1, 3), 16) / 255,
      parseInt(hex.substring(3, 5), 16) / 255,
      parseInt(hex.substring(5, 7), 16) / 255
    )
  }

  static fromHsl(hsl: HSL) {
    const rgb = hsl2rgb(hsl)
    return new Color(rgb.r, rgb.g, rgb.b)
  }

  toHex() {
    return `#${hexFrom01(this.r)}${hexFrom01(this.g)}${hexFrom01(this.b)}`
  }

  multiply(value: number) {
    return new Color(this.r * value, this.g * value, this.b * value)
  }

  adjustLightness(fn: (l: number) => number) {
    const hsl = rgb2hsl(this)
    hsl.l = clamp01(fn(hsl.l))
    return Color.fromHsl(hsl)
  }

  add(other: Color) {
    return new Color(this.r + other.r, this.g + other.g, this.b + other.b)
  }
}

export function clamp01(value: number) {
  return Math.max(0, Math.min(1, value))
}

function hexFrom01(value: number) {
  return Math.floor(value * 255)
    .toString(16)
    .padStart(2, '0')
}

interface RGB {
  r: number
  g: number
  b: number
}

interface HSL {
  h: number
  s: number
  l: number
}

function rgb2hsl({ r, g, b }: RGB) {
  const v = Math.max(r, g, b),
    c = v - Math.min(r, g, b),
    f = 1 - Math.abs(v + v - c - 1)
  const h =
    c && (v == r ? (g - b) / c : v == g ? 2 + (b - r) / c : 4 + (r - g) / c)
  return {
    h: 60 * (h < 0 ? h + 6 : h),
    s: f ? c / f : 0,
    l: (v + v - c) / 2,
  }
}

function hsl2rgb({ h, s, l }: HSL) {
  const a = s * Math.min(l, 1 - l)
  const f = (n: number, k = (n + h / 30) % 12) =>
    l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
  return { r: f(0), g: f(8), b: f(4) }
}
