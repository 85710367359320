import { chordsToPattern } from './chordsToPattern'
import { notes } from './howler'

interface SongOptions {
  bpm: number
  sustain?: boolean
}

export class Song {
  constructor(
    readonly options: SongOptions,
    readonly chords: Note[][],
    readonly pattern: number[]
  ) {
    if (chords.length !== pattern.length) {
      throw new TypeError('Pattern length invalid')
    }
  }

  static fromText(options: SongOptions, text: string, pattern?: number[]) {
    const chords = textToChords(text)
    return new Song(options, chords, pattern ?? chordsToPattern(chords))
  }

  get length() {
    return this.chords.length
  }

  async play() {
    return new Promise<void>((resolve) => {
      let index = 0
      const doPlay = () => {
        if (index === this.chords.length) {
          resolve()
          return
        }
        const delay = this.playChord(index)
        index += 1
        setTimeout(doPlay, delay)
      }
      doPlay()
    })
  }

  playChord(index: number) {
    const chord = this.chords[index]
    const delays = chord.map((note) => this.playNote(note))
    return delays[0]
  }

  private playNote(note: Note) {
    const wholeNoteMs = (4 * 1000 * 60) / this.options.bpm
    const delay = (wholeNoteMs * note.length) / 16
    const pause = (wholeNoteMs * (note.pause ?? 0)) / 16
    const offTime = this.options.sustain ? 3000 : delay * 1.5
    const id = notes[note.key].play()
    setTimeout(() => {
      notes[note.key].fade(1, 0, 150, id)
    }, offTime)
    return delay + pause
  }
}

export interface Note {
  length: number
  pause?: number
  name: 'C' | 'D' | 'E' | 'F' | 'G' | 'A' | 'B'
  octave: number
  flat: boolean
  key: string
}

function textToChords(text: string) {
  const withPauses = text
    .trim()
    .split('\n')
    .filter((x) => !/^\s*\/\//.test(x))
    .join(' ')
    .split(/\s+/)
    .map(textToNotes)
  const chords: Note[][] = []
  for (const chord of withPauses) {
    if (chord.every((x) => x.pause === undefined)) {
      chords.push(chord as Note[])
    } else {
      const note = chords[chords.length - 1][0]
      note.pause = (note.pause ?? 0) + (chord[0].pause ?? 0)
    }
  }
  return chords
}

function textToNotes(text: string) {
  return text.split('+').map(textToNote)
}

function textToNote(text: string): Note | { pause: number } {
  let length = parseInt(text.substring(0, 2))
  const sub = length.toString().length
  let key = text.substring(sub)
  length = 16 / length
  if (key.startsWith('.')) {
    length *= 1.5
    key = key.substring(1)
  }
  if (key === 'p') {
    return {
      pause: length,
    }
  }
  const octave = parseInt(key[key.length - 1])
  let name = key.substring(0, key.length - 1)
  const flat = name.endsWith('b')
  if (flat) {
    name = name.substring(0, name.length - 1)
  }
  return {
    length: length,
    flat,
    name: name as Note['name'],
    octave,
    key,
  }
}
