import { createContext, ReactNode, useEffect, useMemo, useState } from 'react'
import { initAds, prepareRewardAd, showRewardAd } from './ads'

interface AdContextValue {
  ready: boolean
  showAd(): Promise<boolean>
}

export const AdContext = createContext<AdContextValue>({
  ready: false,
  async showAd() {
    return false
  },
})

interface Props {
  children: ReactNode
}

export function AdProvider({ children }: Props) {
  const [initialised, setInitialised] = useState(false)
  const [prepared, setPrepared] = useState(false)
  const [nonce, setNonce] = useState(0)

  useEffect(() => {
    let cancelled = false
    initAds().then((x) => !cancelled && setInitialised(x))
    return () => {
      cancelled = true
    }
  }, [])

  useEffect(() => {
    if (initialised) {
      setPrepared(false)
      let cancelled = false
      prepareRewardAd().then(
        (prepared) => !cancelled && prepared && setPrepared(true)
      )
      return () => {
        cancelled = true
      }
    }
  }, [initialised, nonce])

  async function showReward() {
    if (!prepared || !initialised) {
      return false
    }
    setPrepared(false)
    const rewarded = await showRewardAd()
    setNonce((nonce) => nonce + 1)
    return rewarded
  }

  const value = useMemo(
    () => ({
      ready: prepared && initialised,
      showAd: showReward,
    }),
    [prepared, initialised]
  )

  return <AdContext.Provider value={value} children={children} />
}
