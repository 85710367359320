import { useEffect, useRef } from 'react'
import { ConfettiElement } from './Confetti'
import { Mode } from './useGame'

export function useConfettiRef(mode: Mode) {
  const confetti = useRef<ConfettiElement>(null)
  useEffect(() => {
    if (mode === 'win') {
      confetti.current?.emitParticles()
    }
  }, [mode])
  return confetti
}
