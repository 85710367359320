import { useWindowSize } from '../../hooks'

export function useGridSize(size: number, space?: number) {
  const { width } = useWindowSize()
  const availableSpace = space ?? Math.min(width - 32, 600 - 32)

  const cellCount = Math.round(Math.sqrt(size))
  const cellSize = Math.round(
    Math.min((availableSpace / (cellCount * 6 - 1)) * 5, 150)
  )
  const gapSize = Math.round(cellSize / 5)

  return {
    cellCount,
    cellSize,
    gapSize,
  }
}
