import { Namespace, TFunction, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Icon, IconShape } from '../../components'
import { TEXT_SHADOW } from '../../constants'
import { Mode } from './useGame'

interface Props {
  mode: Mode
}

export function GameMode({ mode }: Props) {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Text>
        <Icon shape={getIconForMode(mode)} inline /> {getTextForMode(t, mode)}
      </Text>
    </Wrapper>
  )
}

const Text = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  filter: ${TEXT_SHADOW};
  padding: 8px;
`

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

function getIconForMode(mode: Mode): IconShape {
  switch (mode) {
    case 'observe':
      return 'look'
    case 'repeat':
      return 'tap'
    case 'win':
      return 'crown'
    case 'loss':
      return 'cross'
  }
}

function getTextForMode(
  t: TFunction<Namespace<'translations'>>,
  mode: Mode
): string {
  switch (mode) {
    case 'observe':
      return t('level.observe')
    case 'repeat':
      return t('level.repeat')
    case 'win':
      return t('level.youWon')
    case 'loss':
      return t('level.youLost')
  }
}
