import { createContext, ReactNode, useEffect, useState } from 'react'
import { init } from './init'

export const InitContext = createContext(false)

interface Props {
  children: ReactNode
}

export function InitProvider({ children }: Props) {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    init().then(() => setInitialized(true))
  }, [])

  return <InitContext.Provider value={initialized} children={children} />
}
