import { Song } from '../../playback/Song'

export const song = Song.fromText(
  { bpm: 140 },
  `
    8G2+8G3+8G4 8G2+8G3+8G4 8G2+8G3+8G4
    2Eb2+2Eb3+2Eb4
    8p 8F2+8F3+8F4 8F2+8F3+8F4 8F2+8F3+8F4
    2D2+2D3+2D4
  `
)
