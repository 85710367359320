import { ReactNode } from 'react'
import styled from 'styled-components'

interface Props {
  children: ReactNode
  size?: number | string
  inline?: boolean
  className?: string
}

export function SvgIcon({ children, inline, size, className }: Props) {
  const defaultSize = inline ? '1em' : 24
  const Svg = inline ? InlineSvg : BlockSvg

  return (
    <Svg
      className={className}
      fill="currentColor"
      width={size ?? defaultSize}
      height={size ?? defaultSize}
      viewBox="0 0 24 24"
    >
      {children}
    </Svg>
  )
}

const BlockSvg = styled.svg`
  pointer-events: none;
  display: block;
`

const InlineSvg = styled.svg`
  pointer-events: none;
  display: inline;
  position: relative;
  bottom: -0.14em;
`
